import * as styles from './Timer.module.scss'

import React from 'react'
import classNames from 'classnames'
import { padStart } from 'lodash'

import { formatDuration } from 'src/utils/formatDuration'

import { TimersState } from './useTimerState'

interface Props {
  timer: TimersState['timers'][number]
  now: Date
  update(values: Partial<TimersState['timers'][number]>): void
  remove(): void
}

export const Timer: React.FC<Props> = (props) => {
  const { timer, now, update, remove } = props

  const secondsTilEnd =
    timer.endDate != null
      ? (timer.endDate.getTime() - now.getTime()) / 1000
      : null

  const timerSeconds =
    secondsTilEnd != null && Math.floor(Math.abs(secondsTilEnd)) % 60

  return (
    <div
      key={timer.id}
      className={classNames(styles.container, {
        [styles.urgent]: secondsTilEnd != null && secondsTilEnd < 60 * 5,
      })}
    >
      <button className={styles.removeButton} type="button" onClick={remove}>
        &times;
      </button>

      <div className={styles.timerName}>
        <div
          className={styles.timerNameInput}
          onBlur={(event) =>
            update({ label: event.currentTarget.textContent ?? '' })
          }
          onKeyDown={(event) => {
            if (event.code === 'Enter') {
              event.preventDefault()
              event.currentTarget.blur()
            }
          }}
          contentEditable="plaintext-only"
          suppressContentEditableWarning
        >
          {timer.label}
        </div>
      </div>

      <div className={styles.timerCountDown}>
        {secondsTilEnd != null && timerSeconds != null ? (
          <>
            {formatDuration(secondsTilEnd, { includeSeconds: false })}
            <span className={styles.timerCountDownSeconds}>
              :{padStart(timerSeconds.toString(), 2, '0')}
            </span>
          </>
        ) : (
          <>--:--</>
        )}
      </div>

      <div className={styles.timerEndTime}>
        <label className={styles.timerEndTimeLabel}>Ends At</label>

        <div
          className={styles.timerEndTimeInput}
          onBlur={(event) =>
            update({ endTimeInput: event.currentTarget.textContent ?? '' })
          }
          onKeyDown={(event) => {
            if (event.code === 'Enter') {
              event.preventDefault()
              event.currentTarget.blur()
            }
          }}
          contentEditable="plaintext-only"
          suppressContentEditableWarning
        >
          {timer.endTimeInput}
        </div>
      </div>
    </div>
  )
}
