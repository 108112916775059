import React from 'react'

import * as controls from 'components/controls'

import { TimersState } from './useTimerState'
import { TimerOptions } from './useTimerOptions'

interface Props {
  timerState: TimersState
  options: TimerOptions
}

export const Controls: React.FC<Props> = (props) => {
  const { timerState, options } = props

  const { reset, addTimer, removeTimer } = timerState

  return (
    <controls.ControlGroup>
      <controls.SmallButton onClick={() => addTimer()}>
        Add
      </controls.SmallButton>

      <controls.SmallButton onClick={() => removeTimer()}>
        Remove
      </controls.SmallButton>

      <controls.SmallButton onClick={() => reset()}>Reset</controls.SmallButton>

      <div> - </div>

      <controls.SegmentedControl
        segments={{
          dark: 'Dark',
          light: 'Light',
        }}
        value={options.darkMode ? 'dark' : 'light'}
        onChange={(event) =>
          options.set({ darkMode: event.currentTarget.value === 'dark' })
        }
      />
    </controls.ControlGroup>
  )
}
