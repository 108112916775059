import { useCallback, useMemo } from 'react'
import { uniqueId } from 'lodash'

import { usePersistentState } from 'src/utils/usePersistentState'

import { parseLooseTime } from './parseLooseTime'

interface Timer {
  id: string
  label: string
  endTimeInput: string
}

interface InternalTimerState {
  timers: Timer[]
}

const defaultState: InternalTimerState = {
  timers: [],
}

function newTimerDefaultTime() {
  const date = new Date()
  date.setHours(date.getHours() + 3)

  const formatter = new Intl.DateTimeFormat('en-us', {
    hour: 'numeric',
    minute: 'numeric',
  })
  return formatter.format(date)
}

export function useTimerState() {
  const [state, setState] = usePersistentState<InternalTimerState>(
    'round-timers-state',
    defaultState,
  )

  const reset = useCallback(() => {
    setState(defaultState)
  }, [setState])

  const addTimer = useCallback(() => {
    setState({
      ...state,
      timers: [
        ...state.timers,
        {
          id: uniqueId(),
          label: 'New Timer',
          endTimeInput: newTimerDefaultTime(),
        },
      ],
    })
  }, [setState, state])

  const removeTimer = useCallback(
    (index?: number) => {
      if (state.timers.length > 0) {
        const newTimers = [...state.timers]
        newTimers.splice(index ?? state.timers.length - 1, 1)
        setState({
          ...state,
          timers: newTimers,
        })
      }
    },
    [setState, state],
  )

  const updateTimer = useCallback(
    (index: number, newValue: Partial<Timer>) => {
      const timers = [...state.timers]
      timers[index] = { ...state.timers[index], ...newValue }
      setState({
        ...state,
        timers,
      })
    },
    [setState, state],
  )

  const exportTimers = useMemo(
    () =>
      state.timers.map((timer) => {
        const endTime = parseLooseTime(timer.endTimeInput)

        const now = new Date()
        const endDate =
          endTime != null
            ? new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate(),
                endTime.hours,
                endTime.minutes,
              )
            : null

        return {
          ...timer,
          endDate,
        }
      }),
    [state.timers],
  )

  return {
    timers: exportTimers,
    reset,
    addTimer,
    updateTimer,
    removeTimer,
  }
}

export type TimersState = ReturnType<typeof useTimerState>
