// title: Magic the Gathering Round Timer

import React from 'react'

import { AccentColor } from 'utils/accent-color/AccentColorContext'

import { BaseLayout } from 'src/components/layout/Base'
import PageMetaData from 'components/layout/PageMetaData'

import { RoundTimer } from 'src/components/round-timer/RoundTimer'

interface Props {
  pageContext: {
    url: string
  }
}

const ListDifferPage: React.FC = () => {
  return (
    <BaseLayout>
      <AccentColor color="#626465">
        <RoundTimer />
      </AccentColor>
    </BaseLayout>
  )
}

export const Head: React.FC<Props> = (props) => {
  const { pageContext } = props

  return (
    <>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <PageMetaData
        metaData={{
          title: 'MTG Round TImer',
          description: '',
          themeColor: '#ffffff',
          ogImageURL: '',
        }}
        url={pageContext.url}
      />
    </>
  )
}

export default ListDifferPage
