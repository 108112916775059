import { useCallback } from 'react'

import { usePersistentState } from 'src/utils/usePersistentState'

const defaultState = {
  darkMode: false,
}

export type TimerOptionsState = typeof defaultState

export function useTimerOptions() {
  const [state, setState] = usePersistentState<TimerOptionsState>(
    'round-timers-view-options',
    defaultState,
  )

  const setOptions = useCallback(
    (value: Partial<TimerOptionsState>) => {
      setState({ ...state, ...value })
    },
    [setState, state],
  )

  return {
    ...state,
    set: setOptions,
  }
}

export type TimerOptions = ReturnType<typeof useTimerOptions>
