import * as styles from './RoundTimer.module.css'

import React from 'react'
import classNames from 'classnames'

import { AccentColor } from 'utils/accent-color/AccentColorContext'

import { useTimerState } from './useTimerState'
import { useTimerOptions } from './useTimerOptions'

import { Controls } from './Controls'
import { Timers } from './Timers'

interface Props {}

export const RoundTimer: React.FC<Props> = () => {
  const timerState = useTimerState()
  const options = useTimerOptions()

  return (
    <AccentColor color={options.darkMode ? '#5C5C5C' : '#8A8A8A'}>
      <div
        className={classNames(styles.container, {
          [styles.dark]: options.darkMode,
        })}
      >
        <Controls timerState={timerState} options={options} />

        <Timers timerState={timerState} />
      </div>
    </AccentColor>
  )
}
