import * as styles from './Timers.module.css'

import React, { useEffect, useState } from 'react'

import { TimersState } from './useTimerState'
import { Timer } from './Timer'

interface Props {
  timerState: TimersState
}

export const Timers: React.FC<Props> = (props) => {
  const { timerState } = props

  const { timers, updateTimer } = timerState

  const [now, setNow] = useState(() => new Date())

  useEffect(() => {
    function updateTime() {
      setNow(new Date())
    }

    const timeoutId = setInterval(updateTime)

    return () => {
      clearInterval(timeoutId)
    }
  })

  return (
    <div className={styles.container}>
      {timers.map((timer, index) => (
        <div key={timer.id} className={styles.timer}>
          <Timer
            timer={timer}
            now={now}
            update={(newValues) => updateTimer(index, newValues)}
            remove={() => timerState.removeTimer(index)}
          />
        </div>
      ))}
    </div>
  )
}
